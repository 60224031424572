<!--
 * @Description: 热门兼职
 * @Author: huacong
 * @Date: 2021-07-06 14:51:25
 * @LastEditTime: 2021-07-19 15:18:57
 * @LastEditors: huacong
-->
<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-size:17px;font-weight:bold;">热门职位</span>
      </div>
      <div class="resume-item">
        <div
          class="resumebox"
          v-for="item in partData"
          :key="item.id"
          @click="goResume(item)"
        >
          <el-row>
            <el-col :span="12" class="infobox fontHover">
              {{ item.j_certificate }}-{{ item.j_major }}
            </el-col>
            <el-col
              :span="12"
              class="infobox text-right font-red"
              style="font-size:16px"
            >
              {{ item.j_min_price }}万-{{ item.j_max_price }}万/{{
                item.j_sign_time
              }}
            </el-col>
            <el-col> {{ item.j_area_province }}-{{ item.j_area_city }} </el-col>
          </el-row>
        </div>
      </div>
      <div class="text-center" style="border-top: 1px solid #e8e8e8;">
        <el-button type="text" @click="goPartTime">更多相似职位</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "hotPartTime",
  data() {
    return {
      partData: [],
      searchInfo: {},
    };
  },
  computed: {
    ...mapState(["talcer2", "talmajor2"]),
  },
  created() {
    // this.getParttimeList();
  },
  methods: {
    ...mapMutations(["changeTalcer2", "changeTalmajor2"]),
    // 获取人员全职列表
    getParttimeList() {
      let talcer2 = this.$route.query.cer;
      let talmajor2 = this.$route.query.major;
      this.searchInfo.page = 1;
      this.searchInfo.limit = 5;
      this.searchInfo.j_certificate = talcer2;
      this.searchInfo.j_major = talmajor2;
      this.searchInfo.position_type = 2;
      this.$http.post("index/parjob/getlist", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
        }
      });
    },
    goResume(item) {
      // // 推荐证书
      // this.changeTalcer2({
      //   talcer2: item.j_certificate,
      // });
      // // 推荐专业
      // this.changeTalmajor2({
      //   talmajor2: item.j_major,
      // });
      // localStorage.setItem("talcer2", item.j_certificate);
      // localStorage.setItem("talmajor2", item.j_major);
      let routeUrl = this.$router.resolve({
        name: "partTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
    goPartTime() {
      this.$router.push({
        name: "partTimeIndex",
        params: { cer: this.talcer2, major: this.talmajor2 },
      });
    },
  },
  mounted() {
    this.getParttimeList();
  },
};
</script>
<style scoped>
.infobox {
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 12px;
  cursor: pointer;
}
.resumebox {
  padding: 20px 0;
}
.resume-item .resumebox:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
</style>
