<!--
 * @Description: 分享
 * @Author: huacong
 * @Date: 2021-07-09 11:01:26
 * @LastEditTime: 2021-07-09 11:14:30
 * @LastEditors: huacong
-->
<template>
  <div>
    <el-popover
      placement="bottom"
      title="打开微信扫一扫"
      width="210"
      trigger="hover"
    >
      <div
        id="qrcode"
        ref="qrcode"
        style="text-align: center;display: block ruby;"
      ></div>
      <img slot="reference" class="mini-icon iconHover" :src="wxIcon" alt="" />
    </el-popover>

    <img
      class="mini-icon iconHover"
      :src="qqIcon"
      @click="share('qq')"
      alt=""
    />
    <img
      class="mini-icon iconHover"
      :src="wbIcon"
      @click="share('sina')"
      alt=""
    />
    <img
      class="mini-icon iconHover"
      :src="kjIcon"
      @click="share('qzone')"
      alt=""
    />
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import $ from "jquery";
export default {
  name: "share",

  data() {
    return {
      kjIcon: require("@/assets/icon/shell-kj.png"),
      qqIcon: require("@/assets/icon/shell-qq.png"),
      wxIcon: require("@/assets/icon/shell-wx.png"),
      wbIcon: require("@/assets/icon/shell-wb.png"),
    };
  },
  methods: {
    weChatShare() {
      $("#qrcode").html("");
      setTimeout(() => {
        let url = window.location.href;
        new QRCode("qrcode", {
          width: 200, // 二维码宽度
          height: 200, // 二维码高度
          text: url, // 地址
        });
      }, 500);
    },
    // 分享
    share(type) {
      //qq空间分享接口
      if (type == "qzone") {
        window.open(
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
            document.location.href +
            "?sharesource=qzone&title=建搜搜&pics=http://uploads-crm-51yixiang.oss-cn-shanghai.aliyuncs.com/zpw_profile/2021/20210621_093125.png&summary=建搜搜"
        );
      }
      //新浪微博接口的传参
      if (type == "sina") {
        window.open(
          "http://service.weibo.com/share/share.php?url=" +
            document.location.href +
            "?sharesource=weibo&title=标题&pic=图片&appkey=微博平台申请的key"
        );
      }
      //qq好友接口的传参
      if (type == "qq") {
        window.open(
          "http://connect.qq.com/widget/shareqq/index.html?url=" +
            document.location.href +
            "?sharesource=qzone&title=标题&pics=图片地址&summary=描述"
        );
      }
    },
  },
  mounted() {
    this.weChatShare();
  },
};
</script>
